<template>
    <layout @search="getPeriodicalList" class="periodical">
        <!-- 面包屑 -->
        <div class="period-box">
            <div class="container bread-list mt15 flex x-left flex-wrap">
                <div class="color999">您所在的位置：</div>
                <a-breadcrumb>
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{path:'/special-ma/index/'+$route.params.id}">首页</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{path:''}">相关期刊</router-link>
                    </a-breadcrumb-item>
                </a-breadcrumb>
            </div>
        </div>
        
        <div class="container perio-list flex mt20">
            <div class="meeting-report mh mt10">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="flex flex-wrap" v-if="periodicalList.length">
                        <div class="flex jounal-items" v-for="(item, index) in periodicalList" :key="index">
                            <journal-item :path="'/special-ma/periodical-detail/'+$route.params.id" width="160px" height="200px" type="2" :journalItem="item"></journal-item>
                        </div>
                    </div>
                    <a-empty style="margin-top:100px;" v-if="!periodicalList.length && !loading" />
                </a-spin>
                <div class="t-c mt30" v-if="periodicalCount > 0">
                    <a-pagination :total="periodicalCount" v-model:current="params.page" :page-size="params.limit" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="meeting-right">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index-ma';
import layoutRight from '@/components/layout/layout-right';
import journalItem from '../../components/journal-item.vue';
import { jsonFormat } from '@/utils/jsonFormat.js';
import { committee_id } from '@/config';
export default {
    name: 'SpecialMaPeriodicalList',
    components: {
        layout,
        layoutRight,
        journalItem
    },
    data() {
        return {
            tabList: [],
            isShowMore: true,
            params: {
                page: 1,
                limit: 10, 
                topic_id: this.$route.params.id, 
                topic_committee_id: committee_id
            },
            periodicalCount: 0,
            periodicalList: [],
            layoutRightConfig: {
                // 相关资讯
                news: {
                    show: true, // 是否显示
                    methods: 'post',
                    type: 'ma',
                    title: '相关资讯', // 标题
                    moreLink:'/special-ma/infomation-list/'+this.$route.params.id,
                    detailLink:'/special-ma/infomation-detail/'+this.$route.params.id,
                    data: {
                        page: 1,
                        limit: 5, 
                        topic_id: this.$route.params.id, 
                        topic_committee_id: committee_id,
                        
                    },   // 参数条件
                },
                meeting: {
                    show: true, // 是否显示
                    title: '相关会议', // 标题
                    methods: 'get',
                    moreLink: '/special-ma/meeting-list/'+this.$route.params.id,
                    type: 'ma',
                    data: {
                        page: 1,
                        pageSize: 6,
                        topic_id: this.$route.params.id, 
                        topic_committee_id:committee_id
                    },   // 参数条件
                },
                video: {
                    show: true, // 是否显示
                    methods: 'get',
                    type: 'ma',
                    title: '相关视频', // 标题
                    moreLink: '/special-ma/video-list/'+this.$route.params.id,
                    data: {
                        page: 1,
                        PageSize: 4,
                        no_count: false,
                        topic_id: this.$route.params.id, 
                        topic_committee_id: committee_id
                    },   // 参数条件
                },
            },
            loading: true,
        }
    },
    created() {
        this.getPeriodicalList();
    },
    // 缓存页面二次进入的钩子函数  动态参数更新时开启
    activated() {
        this.getPeriodicalList();
    },
    methods: {
        // 期刊杂志列表
        getPeriodicalList() {
            this.loading = true

            this.request.post('PeriodicalList', this.params).then(res => {
                let periObj = {
                    desc: 'en_name',
                }
                this.periodicalCount = parseInt(res.data.count)
                this.periodicalList = jsonFormat(res.data.list, periObj)

                this.loading = false
            })
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.params.page = page
            this.getPeriodicalList()
        },
    }
}
</script>
<style lang="less" scoped>
.periodical {
    .filter-box:not(:last-child) {
        border-bottom: 0;
    }

    .meeting-report {
        width: calc(66.66% - 30px);

        .mh {
            min-height: 500px;
        }
    }

    .meeting-right {
        width: 33.33%;
    }

    .jounal-items {
        width: 365px;
        cursor: pointer;
    }
}
@media screen and (max-width:768px){
    .periodical{
        .period-box{
            display: none;
            padding: 0 10px;
            .bread-list{
                position: fixed;
                background: #fff;
                z-index: 999;
                padding-top: 10px;
                margin-top: 0;
            }
        }
        .perio-list{
            display: block;
            width: 100%;
            .meeting-report{
                width: 100%;
                padding: 0 10px;
                .jounal-items{
                    width: 49%;
                }
            }
            .meeting-right{
                width: 100%;
                padding: 0 10px;
                margin-top: 60px;
            }
        }
    }
}
@media screen and (max-width:650px){
    .periodical{
        .perio-list{
            .meeting-report{
                .jounal-items{
                    width: 100%;
                }
            }
        }
    }
}
</style>
